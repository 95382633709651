<template>
  <div class="white-container"></div>
  <h1>
    연세 골프에서는<br>
    이러한 사람들을 모집합니다.
  </h1>
  <div class="container">
    <div class="text-box">
      <div class="single-text">
        ⛳️ 혼자 즐기던 골프를 다른 사람들과 함께하고 싶은 분
      </div>
      <div class="single-text">
        ⛳️ 비슷한 또래와 라운딩하며 실력 향상을 원하는 분
      </div>
      <div class="single-text">
        ⛳️ 골프를 치면서 타과 학생들과 함께 친목을 다지고 싶은 분
      </div>
      <div class="single-text">
        ⛳️ 골프에 대해 남다른 열정을 가지고 있는 분
      </div>
    </div>
  </div>
  <div class="white-container"></div>

</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
h1 {
  margin-top: 100px;
  color: #08366f;

  font-family: 'GmarketSansMedium', sans-serif;

}

.container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;

  font-family: 'Pretendard-Regular', sans-serif;

}

.text-box {
  border: 1px solid #e3e3e3;
  padding: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 95%;
  max-width: 500px;
  height: 95%;
  text-align: left;
  border-radius: 20px;
}

.single-text{
  padding: 1vh;
  padding-left: 4vh;
}

</style>